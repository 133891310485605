@import "variables";
@import "~bootstrap/scss/bootstrap";

/**
* FONT
**/
@font-face 
{
    font-family: Poppins-Light;
    src: url('../fonts/poppins/Poppins-Light.ttf'); 
}

html, body {    
    margin: 0;
    padding: 0;
    height: 100%;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(248, 249, 250);
    overflow-y: hidden;
    font-family: Poppins-Light;
}

/**
 * CONTAINERS
**/
.main-container, .main-container form
{
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    
}

.left-container
{
    height: 100%;
    width: 19%;
    min-width: 10%;
    display: inline-block;
    overflow: auto;
    padding-top: 5px;
}

.right-container
{
    height: 100%;
    width: 80%;
    display: inline-block;
    overflow: auto;
    padding-top: 5px;
}


.side-bar-container
{
    height: 100%;
}


.cls-navbar
{
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
    background: linear-gradient(310deg, $start-gradient 0%, $end-gradient 100%);
}

.cls-left-navbar
{
    top: 10px;
    right: 15px;
    position: absolute;
}


.main-scroll-container
{
    overflow: hidden;
}

.right-scroll-container
{
    display: inline-block;
    overflow: auto;
}

@media only screen and (max-width: 1200px) 
{
    .cls-left-navbar
    {
        top: 8px;
    }

    .main-scroll-container
    {
        overflow: auto;
    }

    .right-scroll-container
    {
        overflow: visible;
    }
}

.nav-link, .navbar-brand
{
    color: rgb(255, 255, 255);
    transition: text-shadow 300ms;
}

.nav-link:hover, .navbar-brand:hover
{
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.48);
}

.nav-link.active
{
    color: rgb(238, 242, 255) !important;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.68);
}

.navbar-button
{
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    color: $start-gradient;
    display: flex;
    justify-content: center;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px;
    text-decoration: none;
    font-weight: 500;
    box-shadow: 0.2rem 0.25rem 0.7rem 0.1rem rgba(0, 0, 0, 0.22);
    transition: box-shadow 300ms;
}

.navbar-button:hover
{
    color: $start-gradient;   
    box-shadow: 0.2rem 0.25rem 0.7rem 0.1rem rgba(0, 0, 0, 0.42);
    text-decoration: none;
    font-weight: 500;
}

/**
 * CARDS
**/
.cls-card
{
    color: rgba(0, 0, 0, 0.87);
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: rgb(255, 255, 255);
    border-width: 0px;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.25rem 0.7rem 0.1rem rgba(0, 0, 0, 0.08);
}

.divider
{
    flex-shrink: 0;
    border-top-width: 0px;
    border-top-style: solid;
    border-top-color: rgba(48, 76, 114, 0.52);
    background-color: transparent;
    height: 0.0625rem;
    margin: 0;
    border-bottom-style: none;
    opacity: 0.25;
    background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0));
}

/**
* FONTS
**/
.ts_h
{
    margin-top: 0px;
    line-height: 1.625;
    letter-spacing: 0.0075em;
    opacity: 1;
    text-decoration-line: none;
    color: rgb(52, 71, 103);
    font-weight: 500;
    font-size: 1.5rem;
}

.ts_h1
{
    @extend .ts_h;
    font-size: 1.25rem;
}

.ts_h2
{
    @extend .ts_h;
    font-size: 1rem;
}

.ts_p
{
    margin-top: 0px;
    font-size: 1rem;
    line-height: 1.25;
    letter-spacing: 0.03333em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration-line: none;
    color: rgb(103, 116, 142);
    font-weight: 400;
}

.ts_p1
{
    @extend .ts_p;
    font-size: 0.90rem;
}

.ts_p2
{
    @extend .ts_p;
    font-size: 0.80rem;
}

.ts_p3
{
    @extend .ts_p;
    font-size: 0.70rem;
}

.ts_p4
{
    @extend .ts_p;
    font-size: 0.60rem;
}

/**
* WIDHT
**/
.w100
{
    width: 100% !important;
}

.w50
{
    width: 50% !important;
}

.w20
{
    width: 20% !important;
}


/**
* BUTTONS
**/
.cls-button
{
    border: 0;
    color: rgb(255, 255, 255);
    background-size: 150% auto;
    box-shadow: rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
}

.cls-button:hover
{
    color: rgb(255, 255, 255);
}

.cls-button:active
{
    color: rgb(255, 255, 255) !important;
}

.small-button
{
    @extend .cls-button;
    padding-top: 2px 2px 2px 2px;
    font-size: 0.7rem;
}

.main-button
{
    @extend .cls-button;
    background: linear-gradient(310deg, $start-gradient 0%, $end-gradient 100%);
    color: rgb(255, 255, 255);
    box-shadow: 0.10rem 0.10rem 0.7rem 0.1rem rgba(0, 0, 0, 0.18);
    transition: box-shadow 300ms;
}

.main-button:hover
{
    box-shadow: 0.10rem 0.10rem 0.7rem 0.1rem rgba(0, 0, 0, 0.32);
}

.white-button
{
    @extend .cls-button;
    background: #FFFFFF;
    color: $start-gradient;
    border: 1px solid #d6d6d6;
    transition: box-shadow 300ms;
}

.white-button:hover, .white-button:checked, .white-button:active
{
    color: $start-gradient !important;
    border: 1px solid #d6d6d6 !important;
    box-shadow: 0.10rem 0.10rem 0.7rem 0.1rem rgba(0, 0, 0, 0.18);
}

.grey-button
{
    @extend .cls-button;
    background: #7d7d7d;
    color: #FFFFFF;
    transition: box-shadow 300ms;
}

.grey-button:hover, .grey-button:checked
{
    color: #FFFFFF;
    background: #7d7d7d !important;   
    background-color: #7d7d7d !important;
    box-shadow: 0.10rem 0.10rem 0.7rem 0.1rem rgba(0, 0, 0, 0.18);
}

.danger-light-button
{
    @extend .cls-button;
    background: linear-gradient(157deg, #d15b5b 29%, #d14e4e 69%);
    color: rgb(255, 255, 255);
    box-shadow: 0.10rem 0.10rem 0.7rem 0.1rem rgba(0, 0, 0, 0.18);
    transition: box-shadow 300ms;
}

.danger-light-button:hover, .danger-light-button:checked
{
    @extend .cls-button;
    background: linear-gradient(157deg, #d15b5b 29%, #d14e4e 69%);
    color: rgb(255, 255, 255);
    box-shadow: 0.10rem 0.10rem 0.7rem 0.1rem rgba(0, 0, 0, 0.24);
}

/**
* OTHER STUFF
**/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.top-shadow
{
    box-shadow: -0.10rem -0.10rem 0.7rem 0.23rem rgba(0, 0, 0, 0.12);
}

.no-radius
{
    border-radius: 0 !important;
}

.filter-container
{
    padding: 10px;
}

.label-archive-div .card-img-top
{
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin: 20px;
    align-self: center;
}

.card-img-container
{
    justify-content: center;
    align-items: center;
    display: flex;
}

.card-img-left
{
    max-width: 100px;
    max-height: 100px;
    margin: 0;
}

.reorder-img
{
    max-width: 250px;
    max-height: 250px;
    margin: 0;
    transition: transform .2s; 
}

img:not([href-zoom=""]):hover
{
    cursor: pointer;
}

.card-text
{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4; 
    -webkit-box-orient: vertical;
}

.label-archive-div .card-text
{
    @extend .card-text;
    min-height: 70px;
}

.order_label_version_div 
{
    display: flex;
    position: relativet;
}

.card-container-row
{
    display: flex;
    flex-wrap: wrap;
}

.expand-element
{
    flex: 1 1 auto; 
}

.label-archive-div
{
    padding-bottom: 20px;
    min-width: 250px;
    text-align: center;
    height: 100%;
}

.labelarchive-detail-img 
{
    padding: 50px;
    
}

.labelarchive-detail-form{
    padding: 50px;
}

.labelarchive-detail-form .form-group{
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 5px;
    padding-top: 20px;
}
  
.filter-container 
{
    padding: 20px 20px 0px 20px;
}

.filter-input-text 
{
    display: block;
    margin: 0;
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 0.4rem;
    background: rgb(245,245,245);
    background: linear-gradient(157deg, rgba(245,245,245,1) 29%, rgba(231,231,231,1) 69%);
    transition: box-shadow 300ms;
    color: rgb(52, 71, 103);
}

.filter-input-text:focus 
{
    outline: none;
    box-shadow: 0.05rem 0.05rem 0.25rem 0.1rem rgba(0, 0, 0, 0.12);
}    

.page-link
{
    text-align: center;
    min-width: 50px;
}

.table-card-header
{
    background: linear-gradient(157deg, rgb(233, 233, 233) 29%, rgba(231,231,231,1) 69%);
    box-shadow: 0.05rem 0.05rem 0.35rem 0.15rem rgba(0, 0, 0, 0.05)
    //padding: 10px 30px 10px 30px;
    //margin-bottom: 15px;
}

.page-item.active, .page-item.active .page-link
{
    background: linear-gradient(310deg, $start-gradient 0%, $end-gradient 100%) !important;
    border: $start-gradient;
}

.page-item:not(.active) .page-link
{
    color: $start-gradient;
}

.page-item
{
    margin-left: 2.5px;
    margin-right: 2.5px;
    border-radius: 20px;
}

.page-item button
{
    border-radius: 20px !important;
}

.invisible-input
{
    border: 0;
}

.drop-down-list
{
    max-width: 100%;
    border-radius: 10px;
    padding: 3px;
    box-shadow: 0px;
    border: 1px solid #f2f1f1;
    background-color: #FFFFFF;
}

.flash-message
{
	width: 50%;
	left: 25%;
	text-align: center;
	background-color: #00B8E6;
	font-size: 1.2em;
	font-weight: bold;
	position: absolute;
	z-index: 9999;
	padding-top:3px;
	color: #e2e2e2;
	padding-bottom:3px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	display: none;
}

.flash-message.ok
{
	background-color: #008100;
}

.flash-message.error
{
	background-color: #d44747;
}

.fab-danger {
    height: 20px;
    width: 20px;
    line-height: 15px;
    font-size: 1em;
    border-radius: 20px;
    background-color: red;
    color: white;
    text-align: center;
    border: none;
    cursor: pointer;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }


.card-delete-button
{
    cursor: pointer;
    padding-top: 4.5px;
    text-align: center;
    position: absolute;
    top: -1px;
    right: -1px;    
    width: 55px;
    height: 35px;
    background: #d14e4e;
    color: #FFFFFF;
    z-index: 1;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 1rem;    
}

.image-with-badge
{
    position: relative;
    padding-bottom: 0px;
}

.image-with-badge svg, .image-with-badge i
{
    font-size: 25px !important;
}
  
.image-with-badge span
{
    position: absolute;
    top: -3px;
    right: 0px;
    display: block;
}

.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}

.text-area-counter
{
    float: right;
    padding: 0.1rem 0 0 0;
    font-size: 0.875rem;
}

.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
    
    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.iubenda-embed
{
    font-size: 8px !important;
}
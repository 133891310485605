$color_light: #c3ebff;
$color_primary: rgb(85, 169, 229);
$color_dark: rgb(112, 147, 234);

$rotas-red: rgb(221, 0, 43);

$start-gradient: rgb(112, 147, 234);
$end-gradient: rgb(85, 169, 229);

//$start-gradient: rgb(161, 33, 59);
//$end-gradient: rgb(204, 27, 63);

